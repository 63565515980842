import { createRouter, createWebHistory } from 'vue-router'; // Import necessary functions from vue-router


// import Converter from './views/ConverterView.vue';

import ConverterView from '../views/ConverterView.vue';
import CallbackView from '../views/CallbackView.vue';
import FileDifference from '../components/FileDIfference.vue';

import TransfomerView from '../views/TransformerView.vue'
import TrashView from '../views/TrashView.vue';
import LoginView from '../views/LoginView.vue';
import IndependentAcceleratorsView from '../views/IndependentAcceleratorsView.vue';
import AllInOneAcceleratorsView from '../views/AllInOneAcceleratorsView.vue';
import SchemaConverterView from '../views/SchemaConverterView.vue';
import KafkaConfigGeneratorView from '../views/KafkaConfigGeneratorView.vue';
import ConnectorConfigGeneratorView from '@/views/ConnectorConfigGeneratorView.vue';
import ManageUsersView from '../views/ManageUsersView.vue';
import UnderConstructionView from '../components/tbd.vue';
import ErrorPageView from '../views/ErrorPageView.vue';
import MapperView from '@/views/MapperView.vue';
import UsageView from '../views/UsageView.vue';
import TransitionView from '../views/TransitionView.vue';
import ActivityTypesView from '../views/ActivityTypesView.vue';
import FieldMappingView from '../views/FieldMappingView.vue';
import ComponentView from '../views/ComponentView.vue';
import ApiView from '../views/ApiView.vue';

import { getCookie } from '../store/cookie';
import { user } from '../utils/util';
import { useAuthStore } from "../store/auth";

const headerTitle = "Psyncopate TIBCO Modernization Suite";
const routes = [
  { 
    path: '/login', 
    name: 'LoginView', 
    component: LoginView, 
    meta: { hideProfileIcon: true, titleColor: 'White', bgColor: '#03122F', title: headerTitle } 
  },
  { 
    path: '/callback', 
    name: 'CallbackView', 
    component: CallbackView 
  },
  { 
    path: '/:projectParamId?',
    name: 'AllInOneAcceleratorsView', 
    component: AllInOneAcceleratorsView, 
    meta: { requiresAuth: true, title: headerTitle } ,
    props: true,
  },
  { 
    path: '/:projectParamId/schemaconverter/:xsdParamId?', 
    name: 'SchemaConverterView', 
    component: SchemaConverterView ,
    meta: {title: headerTitle,requiresAuth: true,},
    props: true,
  },
  { 
    path: '/:projectParamId/kafkaconfiggenerator/:jmsParamId?', 
    name: 'KafkaConfigGeneratorView', 
    component: KafkaConfigGeneratorView,
    meta: {title: headerTitle, requiresAuth: true,} ,
    props: true,
  },
  { 
    path: '/:projectParamId/connectorconfiggenerator/:jmsParamId?', 
    name: 'ConnectorConfigGeneratorView', 
    component: ConnectorConfigGeneratorView,
    meta: {title: headerTitle, requiresAuth: true,} ,
    props: true,
  },
  { 
    path: '/:projectParamId/mapper/:mapperParamId?', 
    name: 'MapperView', 
    component: MapperView,
    meta: {title: headerTitle, requiresAuth: true,} ,
    props: true,
  },
  { 
    path: '/:projectParamId/api/:apiParamId?', 
    name: 'ApiView', 
    component: ApiView,
    meta: {title: headerTitle, requiresAuth: true,} ,
    props: true,
  },
  { 
    path: '/:projectParamId/xsltconverter/:xslParamId?', 
    name: 'ConverterView', 
    component: ConverterView, 
    meta: { requiresAuth: true, title: headerTitle } ,
    props: true,
  },
  { 
    path: '/independent', 
    name: 'IndependentAcceleratorsView', 
    component: IndependentAcceleratorsView, 
    meta: { requiresAuth: true, title: headerTitle },
    redirect: '/xsltconverter',
  },
  {
    path: '/:projectParamId/xsltconverter/:xslParamId?/compare',
    name: 'FileDifferenceNew',
    component: FileDifference,
    props: true,
    meta: { requiresAuth: true, title: headerTitle },
    props: true,
  },
  { 
    path: '/:projectParamId/xsltconverter/:xslParamId/xml-transformer/:xmlParamId?', 
    name: 'XmlTransformView', 
    component: TransfomerView, 
    meta: { requiresAuth: true, title: headerTitle },
    props: true, 
  },
  {
    path: '/:projectParamId/xsltconverter/:xslParamId/xml-transformer/:xmlParamId/compare',
    name: 'XMLFileDifferenceNew',
    component: FileDifference,
    props: true,
    meta: { requiresAuth: true, title: headerTitle },
    props: true,
  },
  { 
    path: '/trash-view', 
    name: 'TrashView', 
    component: TrashView, 
    meta: { requiresAuth: true, title: headerTitle } 
  },
  {
    path: '/manageusers',
    name: 'ManageUsersView',
    component: ManageUsersView,
    meta: { requiresAuth: true, title: headerTitle }
  },
  {
    path: '/underconstruction',
    name: 'UnderConstructionView',
    component: UnderConstructionView,
    meta: { title: headerTitle }
  },
  { 
    path: '/:preMatch(.*)*', 
    name: 'ErrorPageView', 
    component: ErrorPageView, 
    meta: { title: headerTitle } 
  },
  {
    path: '/:projectParamId/usage',
    name: 'UsageView',
    component: UsageView,
    meta: { requiresAuth: true, title: headerTitle },
    props: true,
  },
  {
    path: '/:projectParamId/transition',
    name: 'TransitionView',
    component: TransitionView,
    meta: { requiresAuth: true, title: headerTitle },
    props: true,
  },
  {
    path: '/:projectParamId/activity-types',
    name: 'ActivityTypesView',
    component: ActivityTypesView,
    meta: { requiresAuth: true, title: headerTitle },
    props: true,
  },
  {
    path: '/:projectParamId/field-mapping',
    name: 'FieldMappingView',
    component: FieldMappingView,
    meta: { requiresAuth: true, title: headerTitle },
    props: true,
  }
  ,
  {
    path: '/:projectParamId/components',
    name: 'ComponentView',
    component: ComponentView,
    meta: { requiresAuth: true, title: headerTitle },
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const authStore = useAuthStore();
    const id_token = authStore.id_token;
    const userId = getCookie(user.id);
    if (id_token && userId) {
      next();
    } else {
      next({ path: '/login' });
    }
  } else {
    next();
  }
});
export default router;
