<template >
  <div>
    <ReportTemplate :table-props="dashboardColumns" :key="$route.fullPath" :project-param-id="projectParamId" @statusIconClick="handleStatusIconClick"/>
    <CanvasView :upload-configs="uploadConfigs" />
  </div>
</template>


<script>
// import UploadTemplate from './UploadTemplate.vue'
import "vue3-toastify/dist/index.css";
import ReportTemplate from "../components/ReportTemplate.vue";
import CanvasView from "../components/CanvasView.vue";
import Store from "../vuex/store.js";
import { type,pageViews, app_mode } from "../utils/util";
export default {
  name: pageViews.ALLINONE_ACC_VIEW,
  methods: {
    setValuesToStore(type) {
      Store.commit("setType", type);
    },
    /*callback() {
      const params = new URLSearchParams(window.location.search);
      const status = params.get("status");
      console.log("Status ", status);
      if (status === "success") {
        this.status = true;
        // Handle successful login
        const userId = params.get("user_id");
        console.log("User Id ", userId);
        // Store userId or fetch additional data
      } else if (status === "fail") {
        this.status = false;
        // Handle error
        const message = params.get("message");
        this.$router.push({
          name: "LoginView",
        });
        alert( message);
      }
    },*/
    handleStatusIconClick(transaction, status) {
      if (status.statusValue === "OK") {
        this.$router.push({ name: status.routeTo, params: { projectParamId: transaction.projectId } });
      }
    },
  },
  components: {
      ReportTemplate,
      CanvasView,
    // UploadTemplate
  },
  mounted() {
    //this.callback()
    this.setValuesToStore(type.PROJECT);
  },
  unmounted() {
    console.log("unmounted");
  },

  activated() {
    console.log("activated");
  },
  deactivated() {
    console.log("deactivated");
  },

  data() {
    return {
      // xsltSize: 40,
      // apiSize: 30,
      // connectorSize: 30,
      // deploySize: 28,
      // schemaSize: 25,
      // kafkaSize: 28,
      uploadConfigs: {
        action: "bulk_process",
        actionLabel: "Migrate",
        title: "Migrate",
        type: type.PROJECT,
        accept: ".zip",
      },
      dashboardColumns: {
        type: type.PROJECT,
        isMultiSelectionAllowed: true,
        configs: [
          {
            name: "remarks",
            label: "Remarks",
            order: 7,
            isSortable: false,
            textAlign: "left",
          },
          {
            name: "lastModifiedDate",
            label: "Last Modified Date",
            order: 6,
            type: "date",
          },
          {
            name: "accelatorStatus",
            label: "Accelerator Status",
            order: 5,
            isSortable: false,
            defaultSelected: true,
            computedValue: "",
          },
          {
            name: "status",
            label: "Status",
            order: 4,
            isSortable: false,
            defaultSelected: true,
            search: false,
          },
          {
            name: "fileName",
            label: "File Name",
            order: 3,
            
            defaultSelected: true,
            checked: true,
            textAlign: "left",
          },
          {
            name: "projectId",
            label: "Project ID",
            order: 2,
            
          },
          {
            name: "requestDate",
            label: "Request Date",
            order: 1,
            
            defaultSelected: true,
            type: "date",
          },
        ],
        actions: {
          download: {
            label : 'Download'
          },
          delete: {
            label : 'Delete'
          },
        },
      }
    };
  },
  props: ["projectParamId"]
};
</script>
